<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title
      class="font-weight-light text-h5"
      v-text="name"
    />

    <v-spacer />
    <div
      class="application-header-contact"
      v-text="$t('application-header-contact')"
    ></div>
    <info-component
      :one-time-show="true"
      tooltip-class="app-bar-tooltip"
    >
      <v-col
        class="application-header-info-btn"
        v-html="$t('application-header-info-btn')"
      />
    </info-component>
    <language-selector />
  </v-app-bar>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'
  import InfoComponent from '@/components/app/InfoComponent.vue'
  import LanguageSelector from '@/components/app/LanguageSelector.vue'

  export default {
    name: 'DefaultBar',

    components: {
      DefaultAccount: () => import(
        /* webpackChunkName: "default-account" */
        './widgets/Account'
      ),
      DefaultDrawerToggle: () => import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
      DefaultGoHome: () => import(
        /* webpackChunkName: "default-go-home" */
        './widgets/GoHome'
      ),
      DefaultNotifications: () => import(
        /* webpackChunkName: "default-notifications" */
        './widgets/Notifications'
      ),
      DefaultSearch: () => import(
        /* webpackChunkName: "default-search" */
        './widgets/Search'
      ),
      InfoComponent,
      LanguageSelector,
    },
    data: () => {
      return {
        showInfo: false,
      }
    },
    computed: {
      ...sync('app', [
        'drawer',
        'mini',
      ]),
      name: get('route/name'),
    },
  }
</script>

<style lang="scss">
#default-app-bar {
  .info-component{
    button.info-icon.v-icon{
      background-color: rgba(0,0,0,0) !important;
    }
  }
}

.info-component{
  margin-right: 20px;
  .dn-tooltip{
    color: #fff;
    background-color: $goldairBlue;
  }
}

.application-header-contact {
    font-size: 15px;
    font-weight: 300;
    display: inline-block;
    margin: 3px 60px 0px 0px;
    color: #eaf1f7;
}
</style>
